<template>
  <SubNav class="subnav-position" :selected="'press'" :id="id" />
  <main>
    <form
      v-if="offeringPress"
      @submit.prevent="handleSave"
      class="shadow edit-form"
    >
      <div class="float-end">
        <router-link :to="{ name: 'OfferingPress', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Edit Press Clipping</h6>
      <div class="form-outline mb-4">
        <label>Date</label>
        <input
          type="date"
          required
          v-model="offeringPress.date"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Title</label>
        <input
          type="text"
          required
          v-model="offeringPress.title"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Link to press (www.news.com)</label>
        <input
          type="text"
          required
          v-model="offeringPress.url"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Description</label>
        <QuillEditor
          v-model:content="offeringPress.description"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <div v-if="offeringPress.pressLogoURL" class="edit-large-picture">
          <img :src="offeringPress.pressLogoURL" />
        </div>
        <div>
          <label>Upload Press Image</label>
        </div>
        <div class="error">{{ errorPressLogo }}</div>
        <div v-if="isPending">
          <input type="file" @change="handlePressLogo" disabled />
        </div>
        <div v-else><input type="file" @change="handlePressLogo" /></div>
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'OfferingPress', params: { id: id } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span class="btn btn-link text-danger" @click="handleDelete">
            Delete Press Clipping
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
  <Footer v-if="offeringPress" />
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ref } from "@vue/reactivity";
import SubNav from "@/components/navigation/SubNav.vue";
import useDocument from "@/composables/useDocument";
import getDocument from "@/composables/getDocument";
import useStorage from "@/composables/useStorage";
import { useRouter } from "vue-router";
import Footer from "@/components/navigation/Footer.vue";
import { timestamp } from "@/firebase/config";

export default {
  props: ["id", "pressId"],
  components: { SubNav, Footer, QuillEditor },
  setup(props) {
    const isPending = ref(false);
    const formError = ref(null);
    const router = useRouter();
    const { document: offeringPress } = getDocument(
      "offeringPress",
      props.pressId
    );
    const {
      error: errorOffering,
      deleteDoc: deleteOfferingPress,
      updateDoc: updateOfferingPress
    } = useDocument("offeringPress", props.pressId);

    const {
      error: errorPressLogo,
      url,
      filePath,
      urlThumb,
      filePathThumb,
      urlMedium,
      filePathMedium,
      uploadImageResize,
      deleteImage
    } = useStorage();

    const saveDetails = async () => {
      await updateOfferingPress({
        date: offeringPress.value.date,
        title: offeringPress.value.title,
        url: offeringPress.value.url,
        description: offeringPress.value.description
          ? offeringPress.value.description
          : "",
        pressLogoFilePath: offeringPress.value.pressLogoFilePath
          ? offeringPress.value.pressLogoFilePath
          : "",
        pressLogoURL: offeringPress.value.pressLogoURL
          ? offeringPress.value.pressLogoURL
          : "",
        pressLogoFilePathThumb: offeringPress.value.pressLogoFilePathThumb
          ? offeringPress.value.pressLogoFilePathThumb
          : "",
        pressLogoURLThumb: offeringPress.value.pressLogoURLThumb
          ? offeringPress.value.pressLogoURLThumb
          : "",
        pressLogoFilePathMedium: offeringPress.value.pressLogoFilePathMedium
          ? offeringPress.value.pressLogoFilePathMedium
          : "",
        pressLogoURLMedium: offeringPress.value.pressLogoURLMedium
          ? offeringPress.value.pressLogoURLMedium
          : "",
        editedAt: timestamp()
      });
    };

    const handleSave = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        await saveDetails();
        isPending.value = false;
        if (errorOffering.value) {
          formError.value = errorOffering.value;
        } else {
          router.push({ name: "OfferingPress", params: { id: props.id } });
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    const handleDelete = async () => {
      isPending.value = true;
      formError.value = null;
      if (offeringPress.value.pressLogoFilePath) {
        await deleteImage(offeringPress.value.pressLogoFilePath);
      }
      if (offeringPress.value.pressLogoFilePathThumb) {
        await deleteImage(offeringPress.value.pressLogoFilePathThumb);
      }
      if (offeringPress.value.pressLogoFilePathMedium) {
        await deleteImage(offeringPress.value.pressLogoFilePathMedium);
      }
      await deleteOfferingPress();
      isPending.value = false;
      if (errorOffering.value) {
        formError.value = errorOffering.value;
      } else {
        router.push({ name: "OfferingPress", params: { id: props.id } });
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handlePressLogo = async e => {
      isPending.value = true;
      formError.value = null;
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        errorPressLogo.value = null;
        //Delete the existing images so our resize doesn't break
        if (offeringPress.value.pressLogoFilePath) {
          await deleteImage(offeringPress.value.pressLogoFilePath);
          console.log(`Storage Update: ${errorPressLogo.value}`);
          offeringPress.value.pressLogoFilePath = "";
          offeringPress.value.pressLogoURL = "";
        }
        if (offeringPress.value.pressLogoFilePathThumb) {
          await deleteImage(offeringPress.value.pressLogoFilePathThumb);
          console.log(`Storage Update: ${errorPressLogo.value}`);
          offeringPress.value.pressLogoFilePathThumb = "";
          offeringPress.value.pressLogoURLThumb = "";
        }
        if (offeringPress.value.pressLogoFilePathMedium) {
          await deleteImage(offeringPress.value.pressLogoFilePathMedium);
          console.log(`Storage Update: ${errorPressLogo.value}`);
          offeringPress.value.pressLogoFilePathMedium = "";
          offeringPress.value.pressLogoURLMedium = "";
        }
        errorPressLogo.value = null;
        await uploadImageResize(
          "offeringPress/" + props.id + "/" + props.pressId,
          "logo",
          selected
        );
        if (errorPressLogo.value) {
          formError.value = errorPressLogo.value;
        } else {
          offeringPress.value.pressLogoFilePath = filePath.value;
          offeringPress.value.pressLogoURL = url.value;
          offeringPress.value.pressLogoFilePathThumb = filePathThumb.value;
          offeringPress.value.pressLogoURLThumb = urlThumb.value;
          offeringPress.value.pressLogoFilePathMedium = filePathMedium.value;
          offeringPress.value.pressLogoURLMedium = urlMedium.value;
          await saveDetails();
          if (errorOffering.value) {
            formError.value = errorOffering.value;
          }
        }
      } else {
        formError.value = errorPressLogo.value =
          "Please select an image file (png or jpeg)";
      }
      isPending.value = false;
    };

    return {
      isPending,
      formError,
      offeringPress,
      handleSave,
      handleDelete,
      handlePressLogo,
      errorPressLogo
    };
  }
};
</script>

<style >
</style>